import request from '@/utils/request'
// 查询颜色列表
export function listColor (query) {
  return request({
    url: '/gx/gx-device-text-colour/list',
    method: 'get',
    params: query
  })
}
// 查询颜色分页
export function pageColor (query) {
  return request({
    url: '/gx/gx-device-text-colour/page',
    method: 'get',
    params: query
  })
}

// 查询颜色详细
export function getColor (data) {
  return request({
    url: '/gx/gx-device-text-colour/detail',
    method: 'get',
    params: data
  })
}
// 新增颜色
export function addColor (data) {
  return request({
    url: '/gx/gx-device-text-colour/add',
    method: 'post',
    data: data
  })
}
// 修改颜色
export function updateColor (data) {
  return request({
    url: '/gx/gx-device-text-colour/edit',
    method: 'post',
    data: data
  })
}

// 删除颜色
export function delColor (data) {
  return request({
    url: '/gx/gx-device-text-colour/delete',
    method: 'post',
    data: data
  })
}
